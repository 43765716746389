import React, {useEffect, useState} from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel, MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import {messageService} from "../../../services/messageService";
import userApi from "../../../api/services/userApi";

const ModalValidateUserId = ({ctvApp, openValidateUidModal, setValidateUidModal, handleSetValue}) => {
    const [appSelect, setAppSelect] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [uid, setUid] = useState("");
    let appUid = "";
    const [disableTimeout, setDisableTimeout] = useState(false);

    useEffect(() => {
        if (ctvApp) {
            setAppSelect(ctvApp);
        }
    }, [ctvApp]);

    const handleProfileChanges = (e) => {
        setIsLoading(true);
        e.preventDefault();
        const formData = new FormData(e.target);
        let rawData = {
            app: appSelect,
            appUid: formData.get("uid"),
        };

        setUid(rawData.appUid);
        appUid = rawData.appUid;

        userApi.validateAppUid(rawData, validateSuccess, validateFail);
    }

    function validateSuccess() {
        setIsLoading(false);
        if (appUid) {
            handleSetValue(appUid);
            setValidateUidModal(false);
        }
        setDisableTimeout(true);
        setTimeout(() => setDisableTimeout(false), 45000);
    }

    function validateFail(error) {
        messageService.showMessage(error?.response?.data?.message, "error");
        setIsLoading(false);
        setDisableTimeout(true);
        setTimeout(() => setDisableTimeout(false), 45000);
    }

    const handleAppSelect = (e) => {
        e.preventDefault();
        setAppSelect(e.target.value);
    };

    return (
        <Dialog open={openValidateUidModal} onClose={() => setValidateUidModal(false)}>
            <DialogTitle sx={{fontWeight: "bold"}}>
                Kiểm tra thông tin User ID
            </DialogTitle>
            <DialogContent sx={{width: {xs: "300px", md: "400px"}}}>
                <Stack direction={"column"} spacing={2}>
                    <form onSubmit={handleProfileChanges}>
                        <InputLabel id="app-select-label">App</InputLabel>
                        <FormControl fullWidth variant="outlined" margin="none">
                            <Select
                                id="app-select"
                                fullWidth
                                value={appSelect}
                                displayEmpty
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                onChange={handleAppSelect}
                                variant={"outlined"}>
                                <MenuItem value={"PATI"}>Pati</MenuItem>
                                <MenuItem value={"MICO"}>Mico</MenuItem>
                            </Select>
                        </FormControl>
                        <InputLabel id="balance-label">
                            User ID trên App
                        </InputLabel>
                        <TextField
                            id="uid-input"
                            variant="outlined"
                            fullWidth
                            size="small"
                            defaultValue={uid}
                            slotProps={{
                                inputLabel: {
                                    shrink: false,
                                },
                            }}
                            sx={{mb: 2}}
                            name="uid"
                            required={true}
                        />
                        <Stack direction={"row"} spacing={2}>
                            <Button variant={"contained"} color={"primary"} sx={{width: "100%"}}
                                    type="submit" loading={isLoading.toString()} loadingposition="start"
                                    disabled={disableTimeout}>Kiểm
                                tra</Button>
                        </Stack>
                    </form>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalValidateUserId;