import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Card,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { messageService } from "../../services/messageService";
import transactionApi from "../../api/services/transactionApi";
import { NumericFormat } from "react-number-format";
import { formatMoney, thousandSeparateToOrigin } from "../../utils/utils";
import { useAuth } from "../../contexts/authContext";
import userApi from "../../api/services/userApi";
import { appMap } from "../../utils/constants";
import UserCard from "../../components/ui/userCard/UserCard";

const ChargeDiamond = () => {
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [money, setMoney] = useState('10,000');
    const [errors, setErrors] = useState(false);
    const { username } = useAuth();
    const [userData, setUserData] = useState({});
    const [transactUserInfo, setTransactUserInfo] = useState(false);
    const [isFindFirst, setIsFindFirst] = useState(false);

    useEffect(()=>{
        userApi.userDetail(username, handleGetUserSuccess, showError)
    },[username])

    const handleGetUserSuccess = (response) =>{
        setUserData(response)

    }
    const handleFindTransactUser = () => {
        const userId = document.getElementById("userId-input").value;
        const reqBody = {
            userId: userId || 0,
            app: userData?.ctvApp
        }
        transactionApi.getTransactUserInfo(reqBody, handleGetTransactUserSuccess, handleGetTransactUserFailed)
    }

    const handleGetTransactUserSuccess = (response) =>{
        setTransactUserInfo(response)
        return response;
    }
    const handleGetTransactUserFailed = (error) =>{
        const message = error?.response?.data?.message || "Có lỗi xảy ra";
        messageService.showMessage(message, "error");
        setTransactUserInfo(false)
        return null;
    }

    const handleSubmit = () => {
        setLoading(true);
        let errorFields = {}
        
        handleFindTransactUser();
        
        if(!transactUserInfo){
            errorFields = {...errors, userIdError: 'User ID không tồn tại.'};
        }
        
        const formattedMoney = thousandSeparateToOrigin(money);
        if (formattedMoney % 1000 !== 0) {
            errorFields = {...errors, moneyError: 'Số tiền phải chia hết cho 1,000 và phải lớn hơn 10,000VND'};
        }

        if (errorFields && Object.keys(errorFields).length > 0) {
            setErrors(errorFields);
            setLoading(false);
            setOpenConfirmModal(false);
            return;
        } else {
            setErrors({});
        }
        
        const reqBody = {
            userId: transactUserInfo?.userId || 0,
            transferAmount: formattedMoney,
            app: userData?.ctvApp,
        };
        transactionApi.chargeDiamond(reqBody, handleChargeSuccess, showError);
    };

    const handleChargeSuccess = (response) => {
        const message = "Nạp kim cương thành công";
        messageService.showMessage(message, "success");
        setOpenConfirmModal(false);
        setLoading(false);
    };

    const showError = (error) => {
        const message = error?.response?.data?.message || "Có lỗi xảy ra";
        messageService.showMessage(message, "error");
        setOpenConfirmModal(false);
        setLoading(false);
    };

    return (
        <Container fixed>
            <Card
                sx={{
                    marginTop: "100px",
                    marginBottom: "100px",
                }}
            >
                <Typography
                    variant="h5"
                    color="text.primary"
                    fontWeight={"700"}
                    sx={{ marginBottom: "24px" }}
                    alignContent={"center"}
                >
                    Nạp kim cương
                </Typography>
                <TextField
                    id="app-input"
                    variant="outlined"
                    fullWidth
                    value={appMap[userData?.ctvApp]}
                    size="small"
                    placeholder="App"
                    name="app"
                    disabled
                />
                <Box display="flex" flexDirection="row" gap="16px">
                    <TextField
                        id="userId-input"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Nhập User ID - Vd: 93831010102"
                        name="userId"
                        defaultValue={""}
                        error={!!errors.userIdError}
                        helperText={errors.userIdError}
                    />
                    <Button
                        size="small"
                        variant="contained"
                        sx={{
                            width: "20%",
                            height: "100%",
                            marginBottom: "20px",
                            padding: "8px 16px",
                            lineHeight: "25.6px",
                        }}
                        onClick={() => {
                            setIsFindFirst(true);
                            handleFindTransactUser();
                            }
                        }
                        >
                        Tìm
                    </Button>
                </Box>
                {transactUserInfo && isFindFirst &&
                    <UserCard 
                        avatarUrl={transactUserInfo?.avatar || `https://ui-avatars.com/api/?background=0A4D68&color=fff&name=${transactUserInfo?.name}`}
                        name={transactUserInfo?.name}
                        uid={transactUserInfo?.userId}
                    />
                }
                <Box
                    border={"3px solid"}
                    borderRadius={"10px"}
                    gap={"8px"}
                    display={"flex"}
                    flexDirection={"column"}
                    padding={"16px"}
                >
                    <Typography variant="h6" textAlign={"center"} fontWeight={700} color="text.secondary">
                        Số dư tài khoản: {userData?.moneyInAccount ? formatMoney(userData?.moneyInAccount) : '0'} VND
                    </Typography>
                    <InputLabel id="money-label">Nhập số tiền muốn nạp</InputLabel>
                    <NumericFormat
                        onChange={(e) => setMoney(e.target.value)}
                        customInput={TextField}
                        thousandSeparator
                        valueIsNumericString
                        allowNegative={false}
                        allowLeadingZeros={false}
                        size="small"
                        slotProps={{
                            inputLabel: {
                                shrink: false,
                            },
                            input: {
                                endAdornment: "VND",
                            },
                        }}
                        defaultValue={money}
                        variant="outlined"
                        error={!!errors.moneyError}
                        helperText={errors.moneyError}
                        placeholder="Nhập số tiền bạn muốn nạp"
                        autoComplete="money"
                        name="money"
                    />
                    <InputLabel id="diamond-label">Số kim cương tương ứng</InputLabel>
                    <TextField
                        id="diamond-input"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Số kim cương tương ứng"
                        name="diamond"
                        value={Math.round(thousandSeparateToOrigin(money) / 250)}
                        disabled
                    />
                    <Button
                        onClick={() => {
                            setOpenConfirmModal(true);
                        }}
                        variant="contained"
                        fullWidth
                    >
                        Nạp tiền
                    </Button>
                    <Typography textAlign={"center"} variant="p" fontStyle={"italic"} fontWeight={550} color="text.secondary">
                            Tỷ giá quy đổi: x đồng = 1 kim cương
                    </Typography>
                </Box>
                <Dialog open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
                    <DialogTitle>
                        Xác nhận
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenConfirmModal(false)}
                            sx={{ position: "absolute", right: 8, top: 8 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent sx={{ width: { xs: "300px", md: "400px" } }}>
                        <DialogContentText>Xác nhận nạp kim cương?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirmModal(false)}>Hủy</Button>
                        <LoadingButton loading={loading} onClick={handleSubmit} variant="contained">
                            Xác nhận
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </Card>
        </Container>
    );
};

export default ChargeDiamond;
