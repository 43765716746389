export const CONSTANTS = {
    ACCESS_TOKEN: 'access_token',
    WHITE_LIST_URL: [
        '/auth/register',
        '/auth/authenticate'
    ],
    SUCCESS: 'success',
    ERROR: 'error',
}

export const HTTP_METHOD = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
}

export const thousandSeparateToOrigin = (value) => {
    return value.replace(/,/g, '');
}

export const formatMoney = (amount) => {
    return new Intl.NumberFormat('en-US').format(amount);
  };
