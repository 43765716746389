export const url = {
    login: "/api/auth/login",
    register: "/api/auth/register",
    advanceRequest: "/api/advance/create-request",
    userDetail: "/api/user/detailMember",
    requestPermissionDetail: "/api/request-permission/detail",
    viewAllRequest: "/api/advance/searchByStatus",
    viewAllRequestPermmission: "/api/request-permission/view-all-request",
    partnerRegister: "/api/request-permission/collaborator",
    permissionAction: "/api/request-permission/action-request",
    advanceAdminAction: "/api/advance/action-request",
    getListUsers: "/api/user/viewAllMember",
    changeWalletMoney: "/api/user/change-wallet-money",
    commissionRequest: "/api/request-money-commission/create-request-receive-money",
    viewAllCommissionRequest: "/api/request-money-commission/view-all-request-receive-money",
    lockUser: "/api/user/block-and-unblock-user",
    updateUserProfile: "/api/user/update-information",
    commissionAdminAction: "/api/request-money-commission/action-request-receive-money",
    checkPartnerRegister: "/api/request-permission/is-created-request-permission",
    commissionDetail: "/api/request-money-commission/detail-request",
    getSettingQR: "/api/setting/get-qr-information",
    getDebtInfo: "/api/qr/debt",
    getDepositInfo: "/api/qr/deposit",
    getTransactionHistories: "/api/transaction/all-histories",
    validateAppUid: "/api/auth/validate-app-uid",
    chargeDiamond: "/api/transaction/deposit",
    transactUserInfo: "/api/transaction/user-info",
};
